import React, { useState, useEffect } from "react";

function ToDo(props) {
  const [todoList, setToDoList] = useState([]);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const fetchData = async () => {
      let j;
      try {
        const res = await fetch(process.env.REACT_APP_API_PREFIX + "/todos", {
          mode: "cors",
        });
        // console.log(res);
        j = await res.json();
        console.log(j);
      } catch (e) {
        console.log(e);
        return;
      }

      setToDoList(j);
    };

    fetchData();
  }, []);

  const listItems = todoList.map((todo) => (
    <li key={todo._id}>{`${todo.item} | ${todo.status}`}</li>
  ));
  return (
    <>
      {props.title} <ul>{listItems}</ul>
    </>
  );
}

export default ToDo;
